var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{style:(`
        font-size: 16px;
        text-align: justify;
        line-height: 1.5;
        margin-bottom: 10px;
        color: #464646;
        font-weight: normal;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        ${_vm.needIndent ? 'text-indent: 2em;' : ''}
    `)},[_c('span',{staticStyle:{"font-size":"16px","text-align":"justify","line-height":"1.5","margin-bottom":"10px","color":"#464646","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.desp)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }